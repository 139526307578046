var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-address__container" }, [
    _c("div", { staticClass: "add-address-title__container" }, [
      _c("h3", { staticClass: "address-title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.text.title1) +
            _vm._s(_vm.text.identity) +
            _vm._s(_vm.text.title2) +
            "\n    "
        )
      ])
    ]),
    _c("div", { staticClass: "address-form__container" }, [
      _c(
        "form",
        {
          staticClass: "address-form grid-container",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.createAddress()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "address-form__row grid-item-contact-name",
              staticStyle: { "grid-area": "grid-item-contact-name" }
            },
            [
              _c(
                "div",
                { staticClass: "address-form__field" },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "contactName",
                    attrs: {
                      reference: "contactName",
                      label: _vm.text.contactName,
                      maxlength: 22,
                      isRequired: true
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "address-form__row grid-item-phone inner-phone-grid-container",
              staticStyle: { "grid-area": "grid-item-phone" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "address-form__field left-item",
                  staticStyle: { "grid-area": "left-item" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "areaCode",
                    attrs: {
                      reference: "areaCode",
                      label: _vm.text.areaCode,
                      type: "number",
                      isDisabled: false,
                      isRequired: true
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "address-form__field right-item",
                  staticStyle: { "grid-area": "right-item" }
                },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "phoneNumber",
                    attrs: {
                      reference: "phoneNumber",
                      label: _vm.text.phoneNumber,
                      isRequired: true,
                      maxlength: 8,
                      minlength: 8,
                      type: "tel",
                      helperText: _vm.text.phoneNumberRemainder
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "address-form__row grid-item-select-countries",
              staticStyle: { "grid-area": "grid-item-select-countries" }
            },
            [
              _c(
                "div",
                { staticClass: "address-form__field" },
                [
                  _c("TheMDCSelectOutlined", {
                    ref: "countryMenu",
                    attrs: {
                      reference: "countryMenu",
                      label: _vm.text.countryMenu,
                      isRequired: true,
                      menuList: _vm.countryMenu,
                      event: "choose-country",
                      isDisabled: true
                    },
                    on: { "choose-country": _vm.chooseCountry }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "address-form__row grid-item-select-district",
              staticStyle: { "grid-area": "grid-item-select-district" }
            },
            [
              _c(
                "div",
                { staticClass: "address-form__field" },
                [
                  _c("TheMDCSelectOutlined", {
                    ref: "district",
                    attrs: {
                      reference: "district",
                      label: _vm.text.district,
                      isRequired: true,
                      menuList: _vm.districtMenu,
                      event: "choose-district"
                    },
                    on: { "choose-district": _vm.chooseDistrict }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "address-form__row grid-item-select-area",
              staticStyle: { "grid-area": "grid-item-select-area" }
            },
            [
              _c(
                "div",
                { staticClass: "address-form__field" },
                [
                  _c("TheMDCSelectOutlined", {
                    ref: "area",
                    attrs: {
                      reference: "area",
                      label: _vm.text.area,
                      isRequired: true,
                      menuList: _vm.areaMenu,
                      event: "choose-area"
                    },
                    on: { "choose-area": _vm.chooseArea }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "address-form__row grid-item-detailed-address",
              staticStyle: { "grid-area": "grid-item-detailed-address" }
            },
            [
              _c(
                "div",
                { staticClass: "address-form__field" },
                [
                  _c("TheMDCTextFieldOutlined", {
                    ref: "line1",
                    attrs: {
                      reference: "line1",
                      label: _vm.text.line1,
                      isRequired: true,
                      maxlength: 35,
                      showCounter: true
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "address-form__row grid-item-address-line2",
              staticStyle: { "grid-area": "grid-item-address-line2" }
            },
            [
              _c(
                "div",
                { staticClass: "address-form__field" },
                [
                  _vm.identity === "Receiver"
                    ? _c("TheMDCTextArea", {
                        ref: "line2",
                        attrs: {
                          reference: "line2",
                          label: _vm.text.line2,
                          maxlength: 70,
                          showCounter: true
                        }
                      })
                    : _c("TheMDCTextFieldOutlined", {
                        ref: "line2",
                        attrs: {
                          reference: "line2",
                          label: _vm.text.line2,
                          isRequired: false,
                          maxlength: 35,
                          showCounter: true
                        }
                      })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "address-form__row grid-item-proceed-button inner-grid-button-container",
              staticStyle: { "grid-area": "grid-item-proceed-button" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "address-form__field left-item",
                  staticStyle: { "grid-area": "left-item", height: "100%" }
                },
                [
                  _c("TheMDCButtonShaped", {
                    ref: "return",
                    attrs: {
                      reference: "return",
                      type: "button",
                      label: _vm.text.return,
                      event: "toLastPage"
                    },
                    on: { toLastPage: _vm.toLastPage }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "address-form__field right-item",
                  staticStyle: { "grid-area": "right-item", height: "100%" }
                },
                [
                  _c("TheMDCButtonShapedRaised", {
                    ref: "proceed",
                    attrs: {
                      reference: "proceed",
                      type: "submit",
                      label: _vm.hasThisAddress
                        ? _vm.text.createAddressSave
                        : _vm.text.createAddressAdd
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }