





































































































































































































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { districts, areas } from "@/assets/constant";
import App from "@/App.vue";


// The MDC Components
import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCTextArea from "@/components/mdcComponents/textFields/TheMDCTextArea.vue";
import { ShipmentHelpers } from "@/utils/shipmentHelpers.ts";





@Component({
  components: {
    TheMDCTextFieldOutlined,
    TheMDCSelectOutlined,
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
    TheMDCTextArea
  }
})
export default class AddAddressForm extends Vue implements VuePromptCaller {  // Visiter Pattern
  public on(
    event: "accept" | "close",
    payload?: any,
    execution?: CallBack<void> | null
  ) {
    if (event === "accept") {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  @Prop() action!: "CreateItem" | "ModifyItem";
  @Prop() identity!: "Sender" | "Receiver";
  @Prop() readonly templateId!: string | "0";
  // @Prop() district: string | undefined = "";
  // @Prop() area: string | undefined = "";
  @Ref("contactName") contactNameComponent!: TheMDCTextFieldOutlined;
  @Ref("areaCode") areaCodeComponent!: TheMDCTextFieldOutlined; // not used in api /shipment/create
  @Ref("phoneNumber") phoneNumberComponent!: TheMDCTextFieldOutlined;
  @Ref("countryMenu") countryMenuComponent!: TheMDCSelectOutlined;
  @Ref("district") districtComponent!: TheMDCSelectOutlined; // city in api /shipment/create
  @Ref("area") areaComponent!: TheMDCSelectOutlined; //state in api /shipment/create
  @Ref("postalCode") postalCodeComponent!: TheMDCTextFieldOutlined;
  @Ref("line1") line1Component!: TheMDCTextFieldOutlined;
  @Ref("line2") line2Component!: TheMDCTextFieldOutlined | TheMDCTextArea;
  // @Watch("area")
  // updateAreaSelect(newValue: string, oldValue: string) {
  //   if(this.areaComponent){
  //     this.areaComponent.setSelectedValue
  //   }
  // }

  private ROOT = this.$root.$children[0] as App;

  //using variable because select can't set value after dynamically append options.
  //static variable of area menu array need to be set before the area select component is rendered
  district = "";
  area = "";
  districtMenu: MenuItem[] = [];
  areaMenu: MenuItem[] = [];

  beforeCreate() {
    this.$store.commit("hideNavBar");
    this.$store.commit("hideTitle");
  }

  created(){
    this.updateDistrictMenu();
    this.updateAreaMenu(this.thisTemplateAddress?this.thisTemplateAddress.state:(this.thisAddress?this.thisAddress.state:""));
  }

  mounted() {
  
    this.$nextTick(function () {
      // set predetermined values
      this.preFilled();

      // set address
      if (this.thisAddress) {
        this.fillAddress(this.thisAddress);
      }

      // set template address
      if (this.thisTemplateAddress) {
        this.fillAddress(this.thisTemplateAddress);
      }
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }
  
  get isExpress() {
    return this.$store.getters["shipment/isSubprime"];
  }
  get shipmentType(): string{
    return this.isExpress?"expressOrder":"otherCourier";
  }

  get thisAddress(): Address | null {
    if (this.action === "CreateItem") {
      // brand new Address
      return null;
    } else {
      // if modify address
      if (this.identity === "Sender") {
        return this.$store.getters["shipment/getShipmentSenderAddress"];
      } else {
        return this.$store.getters["shipment/getShipmentReceiverAddress"];
      }
    }
  }
  get thisTemplateAddress(): Address | null {
    if (this.templateId === "0") {
      return null;
    } else {
      return this.$store.getters["historialInput/"+this.shipmentType+"/getUserSavedAddressById"](
        this.templateId
      );
    }
  }

  get text() {
    return {
      title1: this.$t("shipment.shipmentForm.addressForm.title1"),
      identity:
        this.identity === "Sender"
          ? this.$t("shipment.shipmentForm.addressForm.Sender")
          : this.$t("shipment.shipmentForm.addressForm.Receiver"),
      title2: this.$t("shipment.shipmentForm.addressForm.title2"),

      addressMenu: this.$t("shipment.shipmentForm.addressForm.addressMenu"),
      contactName: this.$t("shipment.shipmentForm.addressForm.contactName"),
      areaCode: this.$t("shipment.shipmentForm.addressForm.areaCode"),
      phoneNumber: this.$t("shipment.shipmentForm.addressForm.phoneNumber"),
      phoneNumberRemainder: this.$t("shipment.shipmentForm.addressForm.phoneNumberReminder"),
      countryMenu: this.$t("shipment.shipmentForm.addressForm.countryMenu"),
      district: this.$t("shipment.shipmentForm.addressForm.district"),
      area: this.$t("shipment.shipmentForm.addressForm.area"),
      postalCode: this.$t("shipment.shipmentForm.addressForm.postalCode"),
      line1: this.$t("shipment.shipmentForm.addressForm.line1"),
      line2: this.$t("shipment.shipmentForm.addressForm.line2"),
      return: this.$t("general.return"),
      createAddressSave: this.$t("shipment.shipmentForm.addressForm.createAddressSave"),
      createAddressAdd: this.$t("shipment.shipmentForm.addressForm.createAddressAdd"),
      addressIncomplete: this.$t("shipment.shipmentForm.addressForm.addressIncomplete"),
      requiringDistrict: this.$t("shipment.shipmentForm.addressForm.requiringDistrict"),
      requiringArea: this.$t("shipment.shipmentForm.addressForm.requiringArea"),
      invalidPhoneNumber: this.$t("shipment.shipmentForm.addressForm.invalidPhoneNumber"),
      inputCorrectHKNumber: this.$t("shipment.shipmentForm.addressForm.inputCorrectHKNumber"),
    };
  }



  get hasThisAddress() {
    return this.thisAddress != null;
  }

  private updateDistrictMenu() {
    this.districtMenu = [];
    districts.forEach((value: any) => {
      this.districtMenu.push({
        key : value,
        value: value ,
        expression:value
      });
    });
  }

  private updateAreaMenu(district: string){
    this.areaMenu = [];
    const areasArray: string[] = areas[district];
    if(areasArray){
      areasArray.forEach((value: string) => {
        this.areaMenu.push({
          key: value,
          value: value,
          expression:value
        });
      });
    }
  }

  get countryMenu(): Array<MenuItem> {
    // if not efficient, i.e. for loop everytime
    // we can preprocess it
    const savedCountries = this.$store.getters["shipment/getShipmentCountries"]; // store country
    const countryMenu: MenuItem[] = []; // => menuItem
    for (const [alpha2, coutryName] of Object.entries(savedCountries)) {
      countryMenu.push({
        key: alpha2 as string,
        value: alpha2,
        expression: coutryName
      });
    }
    return countryMenu;
  }

  private chooseCountry(countryCodeAlpha2: string) {
    // hard code if HK, set 0
    if (countryCodeAlpha2 == "HK") {
      this.postalCodeComponent.setValue("0");
    } else {
      this.postalCodeComponent.setValue("");
    }
  }
  

  private chooseDistrict(selectedDistrict = "") {
    this.district = selectedDistrict;
    //following line won't work because of the bug when value can't be assign after dynamic change option list
    this.areaComponent.setSelectedValue("");
    this.updateAreaMenu(selectedDistrict);
    this.area = "";
  }

  

  private chooseArea(selectedArea = ""){
    this.area = selectedArea;
    if(this.areaComponent.getSelectedValue() != selectedArea){
      this.areaComponent.setSelectedValue(selectedArea);
    }
  }

  /**
   * fill in address form by a address
   */
  private fillAddress(address: Address) {
    this.contactNameComponent.setValue(address.contactName);
    this.phoneNumberComponent.setValue(address.contactPhone);
    this.countryMenuComponent.setSelectedValue(address.countryCodeAlpha2);
    this.districtComponent.setSelectedValue(address.state);
    this.district = address.state;
    this.areaComponent.setSelectedValue(address.city);
    this.area = address.city;
    this.line1Component.setValue(address.addressLine1);
    this.line2Component.setValue(address.addressLine2 || "");
  }

  private preFilled() {
    this.areaCodeComponent.setValue("852");
    this.countryMenuComponent.setSelectedValue("HK");
  }
  private resetForm() {
    this.contactNameComponent.setValue("");
    this.phoneNumberComponent.setValue("");
    this.preFilled();
    this.districtComponent.setSelectedValue("");
    this.chooseDistrict("");
    this.chooseArea("");
    this.line1Component.setValue("");
    this.line2Component.setValue("");    
  }
  private isRequiredPrompt(inputName: "district"| "area") {
    this.ROOT.showPrompt({
      title: (this.text.addressIncomplete) as string,
      // acceptBlt: this.$t("dialogs.confirm") as string,
      closeBlt: this.$t("dialogs.close") as string,
      msg: (inputName === "district"?this.text.requiringDistrict:this.text.requiringArea) as string,
      listener: this,
      // execution: this.proceed
    });
  }

    private invalidPhoneNumberPrompt() {
    this.ROOT.showPrompt({
      title: (this.text.invalidPhoneNumber) as string,
      // acceptBlt: this.$t("dialogs.confirm") as string,
      closeBlt: this.$t("dialogs.close") as string,
      msg: (this.text.inputCorrectHKNumber) as string,
      listener: this,
      // execution: this.proceed
    });
  }

  private createAddress() {
    // if has this address, takes its Id
    // else if create new address with template, take template id
    // else create brand new address return null
    const id = this.thisAddress // id can only be null || address.id ( string(36) )
      ? this.thisAddress.id
      : this.thisTemplateAddress
      ? this.thisTemplateAddress.id
      : null;
    const contactName = this.contactNameComponent.getValue();
    const contactPhone = this.phoneNumberComponent.getValue();
    const selectedCountry = this.countryMenuComponent.getSelectedValue();
    const phoneAreaCode = this.areaCodeComponent.getValue();
    const phoneNumber = this.phoneNumberComponent.getValue();
    const district = this.district;
    const area = this.area;
    const addressLine1 = this.line1Component.getValue();
    const addressLine2 = this.line2Component.getValue();
    const phoneNumberCheckerInstance = PhoneNumberUtil.getInstance();
    const numberParsed = phoneNumberCheckerInstance.parse(phoneNumber, selectedCountry || "HK");

    if(!district || !ShipmentHelpers.validateDistrict(district)){
      this.isRequiredPrompt("district");
      return;
    }else if(!area || !ShipmentHelpers.validateArea(district, area)){
      this.isRequiredPrompt("area");
      return;
    }else if (!phoneNumberCheckerInstance.isValidNumberForRegion(numberParsed, selectedCountry || "HK")){
      this.invalidPhoneNumberPrompt();
      return;
    }
    
    const address: Address = {
      id: id,
      contactName: contactName,
      contactPhone: contactPhone,
      countryCodeAlpha2: selectedCountry,
      city: area,
      state: district,
      postalCode: "0",
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      contactEmail: null,
      companyName: null
    };


    if (this.identity == "Sender") {
      this.$store.commit("shipment/saveShipmentSenderAddress", address);
    } else {
      this.$store.commit("shipment/saveShipmentReceiverAddress", address);
    }
    this.toLastPage();
  }
  toLastPage() {
    this.$router.back();
  }
  
}

