












































































































































































import { Component, Prop, Vue, Ref, Watch } from "vue-property-decorator";
import { PhoneNumberUtil, PhoneNumberType, PhoneNumber } from "google-libphonenumber";
// import { districts, areas } from "@/assets/constant";
import App from "@/App.vue";
import AddressPage from "./Address.vue";

// The MDC Components
import TheMDCTextFieldOutlined from "@/components/mdcComponents/textFields/TheMDCTextFieldOutlined.vue";
import TheMDCSelectOutlined from "@/components/mdcComponents/selects/TheMDCSelectOutlined.vue";
import TheMDCButtonShapedRaised from "@/components/mdcComponents/buttons/TheMDCButtonShapedRaised.vue";
import TheMDCButtonShaped from "@/components/mdcComponents/buttons/TheMDCButtonShaped.vue";
import TheMDCTextArea from "@/components/mdcComponents/textFields/TheMDCTextArea.vue";
import { ShipmentHelpers } from "@/utils/shipmentHelpers.ts";

@Component({
  components: {
    TheMDCTextFieldOutlined,
    TheMDCSelectOutlined,
    TheMDCButtonShapedRaised,
    TheMDCButtonShaped,
    TheMDCTextArea,
  },
})
export default class ExpressOrderAddressForm
  extends Vue
  implements VuePromptCaller {
  // Visiter Pattern
  public on(
    event: "accept" | "close",
    payload?: any,
    execution?: CallBack<void> | null
  ) {
    if (event === "accept") {
      if (execution) {
        execution(event, payload);
      }
    }
  }
  @Ref("contactName") contactNameComponent!: TheMDCTextFieldOutlined;
  @Ref("areaCode") areaCodeComponent!: TheMDCTextFieldOutlined; // not used in api /shipment/create
  @Ref("phoneNumber") phoneNumberComponent!: TheMDCTextFieldOutlined;
  // @Ref("countryMenu") countryMenuComponent!: TheMDCSelectOutlined;
  @Ref("district") districtComponent!: TheMDCSelectOutlined; // city in api /shipment/create
  @Ref("area") areaComponent!: TheMDCSelectOutlined; //state in api /shipment/create
  @Ref("postalCode") postalCodeComponent!: TheMDCTextFieldOutlined;
  @Ref("buildingSearch") buildingSearchComponent!: TheMDCTextFieldOutlined;
  @Ref("room") roomComponent!: TheMDCTextFieldOutlined;
  @Ref("floor") floorComponent!: TheMDCTextFieldOutlined;

  private ROOT = this.$root.$children[0] as App;
  // district = "";
  // area = "";
  inputAddress = {} as any;

  //using variable because select can't set value after dynamically append options.
  //static variable of area menu array need to be set before the area select component is rendered
  districtMenu: MenuItem[] = [];
  areaMenu: MenuItem[] = [];

  beforeCreate() {
    this.$store.commit("hideNavBar");
    this.$store.commit("hideTitle");
  }

  created() {
    /**
     * Need to update the select option list before the select is mounted. 
     * 
     */
    this.updateDistrictMenu();
    const districtAreaOptions = ShipmentHelpers.getDistrictAreaByAreaRank(this.referenceAddress.areaRank);
    if(districtAreaOptions && districtAreaOptions.district){
      this.updateAreaMenu(ShipmentHelpers.getDistrictAreaByAreaRank(this.referenceAddress.areaRank).district!.districtFull);
    }

  }

  mounted() {
    //setAddress input field
    this.$nextTick(function () {
      this.fillAddress(this.referenceAddress);
      // Code that will run only after the
      // entire view has been rendered
      this.$store.commit("hideLoading");
    });
  }

  get addressMutation(){
    return this.$parent.$props.addressMutation;
  }

  get title(){
    return this.$parent.$props.title;
  }

  get savedAddressGetter(){
    return this.$parent.$props.savedAddressGetter;
  }

  get addressId(){
    return this.$parent.$props.addressId;
  }

  get completingRedirectName(){
    return this.$parent.$props.completingRedirectName;
  }

  /**
   * get address we are referencing
   * 
   */
  get referenceAddress() {
    return this.addressFromParent || this.savedAddress || ShipmentHelpers.emptyAddress;
  }

  get addressFromParent(): ExpressOrderAddress{
    return (this.$parent as AddressPage).address as ExpressOrderAddress;
  }

  get savedAddress(){
    if(this.savedAddressGetter){
      if(this.addressId){
        const addresses = this.$store.getters[this.savedAddressGetter as string];
        if(addresses && addresses.length > 0){
          return addresses.find((address: ExpressOrderAddress) => address.id === this.addressId);
        }
      }else{
        return this.$store.getters[this.savedAddressGetter];

      }
    }
  }

  get buildingAddress(){
    return (this.$parent as AddressPage).buildingAddress;
  }
  get isExpress() {
    return this.$store.getters["shipment/isSubprime"];
  }
  get districts() {
    return this.$store.getters["address/getDistricts"];
  }

  get text() {
    return {
      title1: this.$t("shipment.shipmentForm.addressForm.title1"),
      title2: this.$t("shipment.shipmentForm.addressForm.title2"),

      addressMenu: this.$t("shipment.shipmentForm.addressForm.addressMenu"),
      contactName: this.$t("shipment.shipmentForm.addressForm.contactName"),
      areaCode: this.$t("shipment.shipmentForm.addressForm.areaCode"),
      phoneNumber: this.$t("shipment.shipmentForm.addressForm.phoneNumber"),
      phoneNumberRemainder: this.$t(
        "shipment.shipmentForm.addressForm.phoneNumberReminder"
      ),
      countryMenu: this.$t("shipment.shipmentForm.addressForm.countryMenu"),
      district: this.$t("shipment.shipmentForm.addressForm.district"),
      area: this.$t("shipment.shipmentForm.addressForm.area"),
      postalCode: this.$t("shipment.shipmentForm.addressForm.postalCode"),
      line1: this.$t("shipment.shipmentForm.addressForm.line1"),
      line2: this.$t("shipment.shipmentForm.addressForm.line2"),
      return: this.$t("general.return"),
      createAddressSave: this.$t(
        "shipment.shipmentForm.addressForm.createAddressSave"
      ),
      createAddressAdd: this.$t(
        "shipment.shipmentForm.addressForm.createAddressAdd"
      ),
      addressIncomplete: this.$t(
        "shipment.shipmentForm.addressForm.addressIncomplete"
      ),
      requiringDistrict: this.$t(
        "shipment.shipmentForm.addressForm.requiringDistrict"
      ),
      requiringArea: this.$t("shipment.shipmentForm.addressForm.requiringArea"),
      invalidPhoneNumber: this.$t(
        "shipment.shipmentForm.addressForm.invalidPhoneNumber"
      ),
      inputCorrectHKNumber: this.$t(
        "shipment.shipmentForm.addressForm.inputCorrectHKNumber"
      ),
    };
  }

  get hasSavedAddress() {
    return !!this.savedAddress;
  }

  get isFreeInput(){
    return this.buildingAddress && this.buildingAddress?.isFreeInput;
  }


  private getInputAddress(): ExpressOrderAddress {
    // if has this address, takes its Id
    // else if create new address with template, take template id
    // else create brand new address return null
    let id = null;
    if(this.referenceAddress){
      id = this.referenceAddress.id;
    }
    //TODO: update area to areaRank
    return {
      id: id,
      contactName: this.contactNameComponent.getValue() || "",
      phoneAreaCode: this.areaCodeComponent.getValue() || "",
      contactPhone: this.phoneNumberComponent.getValue() || "",
      areaRank: this.inputAddress.areaRank,
      streetEn: "",
      streetChi: "",
      buildingEn: "",
      buildingChi: "",
      floor: this.floorComponent.getValue(),
      room: this.roomComponent.getValue(),
    } as ExpressOrderAddress;
  }

  // update @Prop address in Address.vue(parent)
  private setParentBuildingAddress() {
    (this.$parent as AddressPage).address = this.createAddress();
  }

  private updateDistrictMenu() {
    this.districtMenu = [];
    if(this.districts){
      this.districts.forEach((district: District) => {
        this.districtMenu.push({
            key: district.districtFull,
            value: district.districtFull,
            expression: (this.$i18n.locale === "cant"?district.districtChi:district.districtEn) + (!district.canBeDelivered?this.$t('general.leftBracket') as string + this.$t('shipment.shipmentForm.addressForm.serviceNotAvailable') + this.$t('general.rightBracket') as string:""),
            disabled: !district.canBeDelivered});
      });
    }    
  }

  private updateAreaMenu(districtFull: string) {
    this.areaMenu = [];
    const areas: Area[] = this.$store.getters["address/getAreasByDistrict"](districtFull);
    if (areas) {
      areas.forEach((area: Area) => {
        if(area.canBeDelivered){
          this.areaMenu.push({
            key: area.areaRank,
            value: area.areaRank,
            expression: (this.$i18n.locale === "cant"?area.areaChi:area.areaEn) + (!area.canBeDelivered?this.$t('general.leftBracket') as string + this.$t('shipment.shipmentForm.addressForm.serviceNotAvailable') + this.$t('general.rightBracket'):""),
            disabled: !area.canBeDelivered

          });
        }
      });
    }
  }

  private chooseDistrict(selectedDistrict = "") {
    this.inputAddress.district = selectedDistrict;
    //following line won't work because of the bug when value can't be assign after dynamic change option list
    this.areaComponent.setSelectedValue("");
    this.updateAreaMenu(selectedDistrict);
    this.inputAddress.areaRank = "";
  }

  private chooseArea(selectedArea = "") {
    this.inputAddress.areaRank = selectedArea;
  }

  private prefilled() {
    this.areaCodeComponent.setValue("852");
    this.inputAddress.phoneAreaCode = "852";
  }
  /**
   * fill in address form by input/template address
   */
  private fillAddress(address: ExpressOrderAddress) {
    this.contactNameComponent.setValue(address.contactName);
    this.phoneNumberComponent.setValue(address.contactPhone);
    this.floorComponent.setValue(address.floor);
    this.roomComponent.setValue(address.room);

    this.inputAddress = Object.assign({},address);
    this.prefilled();
    
    try{
      const areaRankObject = ShipmentHelpers.getDistrictAreaByAreaRank(address.areaRank)
      const district = areaRankObject!.district?areaRankObject!.district!.districtFull : "";
      this.inputAddress["district"] = district;
      if(district){
        this.districtComponent.setSelectedValue(district);
      }
      const area = address.areaRank;
      this.inputAddress["areaRank"] = area;
      // this.inputAddress.area = area;
      if(area){
        this.areaComponent.setSelectedValue(area);
      }

      //check if district and area is available for delivery
      //if it is not, jsut empty district area and building Address
      if((areaRankObject.area && !areaRankObject.area.canBeDelivered) || (areaRankObject.district && !areaRankObject.district.canBeDelivered)){
        throw ("noAvailableAreaRank");
      }else if(this.buildingAddress){
        this.buildingSearchComponent.setValue((this.$i18n.locale === "cant"?this.buildingAddress!.fullAddressChi : this.buildingAddress!.fullAddressEn) || "");

      }else if (address.fullAddress){
        (this.$parent as AddressPage).buildingAddress = ShipmentHelpers.convertAddressToBuildingAddress(address);
        this.buildingSearchComponent.setValue(address.fullAddress || "");
      }
    }catch(e){
      //TODO: add fill district area dialog
      console.log(e);
      this.chooseDistrict("");
      this.chooseArea("");
      this.buildingSearchComponent.setValue("");
      ((this.$parent as AddressPage).buildingAddress as BuildingAddress).areaRank = "";
      ((this.$parent as AddressPage).buildingAddress as BuildingAddress).isFreeInput = true;
      (this.$parent as AddressPage).buildingAddress = ShipmentHelpers.emptyBuildingAddress;

    }
  }

  get validateInputAddress(){

    return this.inputAddress.contactName && this.inputAddress.contactPhone && this.inputAddress.areaRank && this.buildingAddress && this.inputAddress.floor && this.inputAddress.room;
  }

/**
 * create address object with input field data
 */
  private createAddress(){
    // if has this address, takes its Id
    // else if create new address with template, take template id
    // else create brand new address return null
    const id = this.referenceAddress // id can only be null || address.id ( string(36) )
      ? this.referenceAddress.id
      : null;
    return {
      id: id,
      contactName: this.inputAddress.contactName || "",
      phoneAreaCode: this.inputAddress.phoneAreaCode || "",
      contactPhone: this.inputAddress.contactPhone || "",
      areaRank: this.inputAddress.areaRank || "",
      fullAddress: (this.buildingAddress?this.buildingAddress.fullAddressChi:""),
      streetEn: (this.buildingAddress?this.buildingAddress.streetEn:""),
      streetChi: (this.buildingAddress?this.buildingAddress.streetChi:""),
      buildingEn: (this.buildingAddress?this.buildingAddress.buildingEn:""),
      buildingChi: (this.buildingAddress?this.buildingAddress.buildingChi:""),
      isFreeInput: (this.buildingAddress?this.buildingAddress.isFreeInput:false),
      floor: this.inputAddress.floor,
      room: this.inputAddress.room,
    } as ExpressOrderAddress;
  }

  
  private resetForm() {
    this.contactNameComponent.setValue("");
    this.phoneNumberComponent.setValue("");
    this.prefilled();
    this.districtComponent.setSelectedValue("");
    this.chooseDistrict("");
    this.chooseArea("");
    this.buildingSearchComponent.setValue("");
    (this.$parent as AddressPage).address = null;
    (this.$parent as AddressPage).buildingAddress = null;
  }

  private isRequiredPrompt(inputName: "district" | "area" | "buildingAddress") {
    let message = "";
    switch (inputName) {
      case "district":
        message = this.text.requiringDistrict as string;
        break;
      case "area":
        message = this.text.requiringArea as string;
        break;
      case "buildingAddress":
        message = this.$t(
          "shipment.shipmentForm.addressForm.requiringBuilding"
        ) as string;
        break;
    }
    this.ROOT.showPrompt({
      title: this.text.addressIncomplete as string,
      // acceptBlt: this.$t("dialogs.confirm") as string,
      closeBlt: this.$t("dialogs.close") as string,
      msg: message,
      listener: this,
      // execution: this.proceed
    });
  }

  private invalidPhoneNumberPrompt() {
    this.ROOT.showPrompt({
      title: this.text.invalidPhoneNumber as string,
      // acceptBlt: this.$t("dialogs.confirm") as string,
      closeBlt: this.$t("dialogs.close") as string,
      msg: this.text.inputCorrectHKNumber as string,
      listener: this,
      // execution: this.proceed
    });
  }  


  private addressCompleted() {
      //   this.$store.commit("showLoading");
    const phoneNumberCheckerInstance = PhoneNumberUtil.getInstance();
    const address = this.createAddress();
    const numberParsed = phoneNumberCheckerInstance.parse(address.contactPhone, "HK");
    if ((!address.areaRank) || (!ShipmentHelpers.validateAreaRank(address.areaRank))) {
      this.isRequiredPrompt("area");
      return;
    } else if (
      address.phoneAreaCode !== "852"
        || !phoneNumberCheckerInstance.isValidNumberForRegion(numberParsed,"HK")
        // || phoneNumberCheckerInstance.getNumberType(numberParsed) != PhoneNumberType.MOBILE
    ) {
      this.invalidPhoneNumberPrompt();
      return;
    }
  
    this.$store.commit(this.addressMutation, address);
    this.redirectTo();
  }

  private async buildingSearch() {
    this.setParentBuildingAddress();
    await this.$router.push({
      name: "BuildingSearch",
      params: {
        addressMutation: this.addressMutation,
        savedAddressGetter: this.savedAddressGetter,
        addressId: this.addressId,
        title: this.title,
        completingRedirectName: this.completingRedirectName,
        address: this.getInputAddress() as any,
      },
    })
    .catch((e)=>{
      this.$store.commit("dialog/showErrorDialog", {title: this.$t("dialogs.routerErrorPrompt.title"),msg: this.$t("dialogs.routerErrorPrompt.message"), closeBlt: this.$t("dialog.close")});
    });
  }


  async redirectTo() {
    this.$store.commit("showLoading");
    await this.$router.push({ name: this.completingRedirectName })
    .catch((e)=>{
      this.$store.commit("dialog/showErrorDialog", {title: this.$t("dialogs.routerErrorPrompt.title"),msg: this.$t("dialogs.routerErrorPrompt.message"), closeBlt: this.$t("dialog.close")});
    });
  }
  // private proceed() {
  //   this.$store.commit("showLoading");
  // }
}
